export const navLinksdata = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Birthday",
    link: "/birthday",
  },
  {
    title: "Pac-Can",
    link: "/pac-can",
  },
];
